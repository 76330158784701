body {
  line-height: 1.5;
  background: black;
  color: #fff;
  overflow-y: auto;
}

#root {
  width: 100%;
  z-index: 1;
}

.body__bg-image {
  background-image: url('../public/img/bg-100.jpg');
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  opacity: 0.4;
}

.card {
  border-radius: 0.25rem;
  text-decoration: none;
  background: linear-gradient(247deg, rgba(255, 255, 255, 0.1) 2.66%, rgba(58, 58, 58, 0.1) 80.19%);
  box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.3) inset;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-backdrop-filter: blur(13px);
  backdrop-filter: blur(13px);
  color: white;
}

a.card:hover {
  background: linear-gradient(247deg, rgba(255, 255, 255, 0.2) 2.66%, rgba(58, 58, 58, 0.2) 80.19%);
}

/* Animations */
@media (prefers-reduced-motion: no-preference) {
  .animate-spin {
    animation: infinite-spin infinite 4s linear;
  }
  .animate-shimmer {
    animation: shimmer 2s infinite;
  }
}

@keyframes infinite-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Button transitions */
button, a.button, .hover-grow {
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

button:not(:disabled):hover, a.button:not(:disabled):hover, .hover-grow:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

button:not(:disabled):active, a.button:not(:disabled):active, .hover-grow:active {
  transform: scale(0.98); /* Slightly shrink when clicked */
}

.no-transform:hover, 
.no-transform:active {
  transform: none !important; /* Disable any scaling effects */
}
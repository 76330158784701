@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/jum1mgq.css");

body {
  font-family: "halyard-display", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}